import useLockScroll from '@/services/hooks/useLockScroll';
import React, { useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { Transition } from 'react-transition-group';
import styles from './Modal.module.scss';

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	title?: React.ReactNode;
	subTitle?: React.ReactNode;
	footer?: React.ReactNode;
	width?: string | number;
	maskClosable?: boolean;
	onOk?: () => void;
	children: React.ReactNode;
	modalStyle?: React.CSSProperties; // for the modal's main container
	bodyStyle?: React.CSSProperties; // for the modal's content/body
	closeIcon?: boolean;
	fullHeight?: boolean;
	otherItems?: any;
}

const Modal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	title,
	subTitle,
	footer = (
		<React.Fragment>
			<button onClick={onClose}>Cancel</button>
			<button onClick={onClose}>OK</button>
		</React.Fragment>
	),
	width = '520px',
	maskClosable = true,
	onOk,
	children,
	modalStyle = {},
	bodyStyle = {},
	closeIcon = true,
	fullHeight = false,
	otherItems
}) => {
	const handleBackdropClick = (e: React.MouseEvent) => {
		if (maskClosable) {
			onClose();
		}
	};

	useEffect(() => {
		if (isOpen) {
			// Prevent scrolling
			document.body.style.overflow = 'hidden';
		} else {
			// Re-enable scrolling
			document.body.style.overflow = '';
		}

		// Cleanup function to ensure scrolling is enabled when component unmounts
		return () => {
			document.body.style.overflow = '';
		};
	}, [isOpen]);

	return (
		<Transition in={isOpen} timeout={300} unmountOnExit>
			{(state: any) => (
				<div
					className={`${styles.modalBackdrop} ${styles[state]}`}
					onClick={handleBackdropClick}
					style={{
						...modalStyle
					}}>
					<div
						className={`${styles.modalContent} ${styles[state]}-${isOpen ? 'active' : 'leave'} `}
						onClick={(e) => e.stopPropagation()}
						style={{
							width: typeof width === 'number' ? `${width}px` : width
							// maxHeight: !fullHeight ? "calc(100% - 64px)" : "100%",
						}}>
						{title && (
							<div className={styles.modalHeader}>
								<div>
									{title}
									{subTitle && (
										<p style={{ color: '#718096', fontSize: '14px', fontWeight: '400', margin: '10px 0px' }}>
											{subTitle}
										</p>
									)}
								</div>

								{closeIcon && (
									<div className={styles.closeIcon} onClick={onClose}>
										<MdOutlineClose size={24} />
									</div>
								)}
								{otherItems && otherItems}
							</div>
						)}
						<div
							className={styles.modalBody}
							style={{
								...bodyStyle
								// maxHeight: !fullHeight ? "calc(100vh - 250px)" : "100%",
							}}>
							{children}
						</div>
						{footer && <div className={styles.modalFooter}>{footer}</div>}
					</div>
				</div>
			)}
		</Transition>
	);
};

export default Modal;
